@font-face {
  font-family: "Data Control";
  src: url(./fonts/data-latin.ttf) format("truetype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}