#search input {
  width: 80%;
}

#search-results {
  width: 80%;
  max-height: 60vh;
  overflow-y: scroll;
}

#search-results ul li {
  border-top: 1px solid #aaa;
  cursor: pointer;
}

#search-results ul li:first-child {
  border-top: none;
}
