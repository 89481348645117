 header {
   background-color: #72ade1;
   z-index: 2000;
   box-shadow: 0px 2px 4px #333;
   border-bottom: none;
 }

 header>a,
 a:visited {
   color: #000;
   text-decoration: none;
   display: block;
 }

 .leaflet-control-container {
   position: relative;
   top: 10rem;
 }