
footer {
  background-color: #72ade1;
  width: 100%;
  border-top: 1px solid #888;
  margin-top: 2rem;
  text-shadow: 1px 1px 3px #116;
  z-index: 2000;
}
/* 
footer.floating {
  position: fixed;
  bottom: 0;
  left: 0;
  
  box-shadow: 0px -2px 4px #333;
  border-top: none;
} */

footer p {
  margin: 0.25rem 0 0rem 1rem;
}
